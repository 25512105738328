<template>
  <vue-page-transition name="overlay-right">
    <router-view />
  </vue-page-transition>
</template>

<script>
export default {
  name: "Default",
};
</script>

<style>
</style>